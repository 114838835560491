<template>
  <div class="detail_wrap">
    <div class="list_right">
      <div class="fixed_top">
        <div class="list_rigth_title">
          <strong>{{detailData.pubNoAll}}</strong>
          <span
            :class="detailData.lawState == 'valid' ? 'effective' : detailData.lawState == 'review' ? 'trial' : detailData.lawState == 'invalid' ? 'invalid' : detailData.lawState == 'default' ? 'unknown' :''">{{detailData.lawState == 'valid' ? '有效' : detailData.lawState == 'review' ? '审核中' : detailData.lawState == 'invalid' ? '无效' : detailData.lawState == 'default' ? '未知' :''}}</span>
          <h6 v-html="getHighlightTitle(detailData.title)"></h6>
        </div>
        <ul class="list_right_tab">
          <li :class="currentDetailIndex == index ? 'active' : ''" v-for="(item,index) in detailTab" :key="index"
            @click="goAnchor('#anchor-'+index,index)">{{item.name}}</li>
        </ul>
      </div>
      <div class="fixed_bottom">
        <ul class="list_right_tab_con">
          <li id="anchor-0" name="anchor-0">
            <p>
              <span>
                公开号：
                <strong>{{detailData.pubNoAll}}</strong>
              </span>
              <span>
                公开/公告日：
                <strong>{{detailData.pubDate}}</strong>
              </span>

              <span>
                申请号：
                <strong>{{detailData.applyNoAll}}</strong>
              </span>
              <span>
                申请日期：
                <strong>{{detailData.applyDate}}</strong>
              </span>

              <span>
                申请人：
                <strong v-for="(item,index) in detailData.applicants" :key="index">{{item}}</strong>
              </span>
              <span>
                发明人：
                <strong v-for="(item,index) in detailData.inventors" :key="index">{{item}}</strong>
              </span>

              <span>
                专利类型：
                <strong>{{detailData.kind}}</strong>
              </span>
              <span v-if="detailData.mainIpcs && detailData.mainIpcs.length != 0">
                IPC分类号：
                <strong v-for="(item,index) in detailData.mainIpcs" :key="index">{{item}}</strong>
              </span>

              <span>
                优先权国家：
                <strong v-for="(item,index) in detailData.patentPriorityClaimsVos"
                  :key="index">{{item.country}}</strong>
              </span>
              <span>
                优先权日：
                <strong v-for="(item,index) in detailData.patentPriorityClaimsVos" :key="index">{{item.date}}</strong>
              </span>
            </p>
            <h6>摘要</h6>
            <p v-html="detailData.abstractInfo"></p>
          </li>
          <li>
            <h6>全文</h6>
          </li>
          <li id="anchor-1" name="anchor-1" class="pd10">
            <h5>权利要求</h5>
            <p v-html="detailData.claim"></p>
          </li>
          <li id="anchor-2" name="anchor-2" class="pd10">
            <h5>说明书</h5>
            <p v-html="detailData.instruction"></p>
          </li>
          <li id="anchor-3" name="anchor-3" class="pd10">
            <h5>附图</h5>
            <p>
              <img v-for="(item,index) in detailData.figureImages" :key="index" :src="item" alt
                @click="dialogSwiperVisible = true;" />
            </p>
          </li>
        </ul>
      </div>
    </div>
    <!-- 轮播图 -->
    <el-dialog :visible.sync="dialogSwiperVisible" custom-class="swiper_dialog" width="60%">
      <div class="swiper-box" style="background:#fff;">
        <swiper :options="swiperOption">
          <swiper-slide class="swiper-slide" v-for="(item,index) in detailData.figureImages" :key="index">
            <img :src="item" />
          </swiper-slide>
          <!-- 左右箭头 -->
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
          <!-- 分页器 -->
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
      <!-- <div class="swiper_right"
        style="margin-left:100px;width:190px;height:500px;background:transparent;display:inline-block;">
        <swiper :options="swiperOptionThumbs" ref="swiperThumbs">
          <swiper-slide class="swiper-slide" v-for="(item,index) in imageArr" :key="index">
            <img :src="item" />
          </swiper-slide>
        </swiper>
      </div>-->
    </el-dialog>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
export default {
  name: 'patentSearchDetail',
  mounted () {
    this.detailAjax();
  },
  data () {
    return {
      detailData: {},
      //   详情tab切换
      detailTab: this.$t(`PS_detail.detailTab`),
      currentDetailIndex: 0,
      //   轮播图是否显示
      dialogSwiperVisible: false,
      swiperOption: {
        //显示分页
        pagination: {
          el: ".swiper-pagination",
          type: "fraction"
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
        //开启循环模式
        // loop: true
      }
    };
  },
  methods: {
    ...mapMutations(["loadingBool"]),
    // 获取详情
    async detailAjax () {
      this.loadingBool(true);
      let data = await this.$axios.get(
        `/patent/search/searchPatent/${this.$route.query.id}`
      );
      if (data.data.code == 0) {
        this.detailData = data.data.data;
      }
      this.loadingBool(false);
    },
    getHighlightTitle: function(data) {
      if (data) {
        // 一种TiO sub &gt; 2  &lt;/sub&gt;  纳米颗粒/TiO  &lt;sub&gt;   2  &lt;/sub&gt;  纳米管阵列及其应用

        var regLt = new RegExp("&lt;", "g"),
          regGt = new RegExp("&gt;", "g");

        var title = data.replace(regLt, "<").replace(regGt, ">");
        return title;
      } else {
        return "";
      }
    },
    detailTabEvent (index) {
      if (index == this.currentDetailIndex) {
        return;
      }
      this.currentDetailIndex = index;
    },
    goAnchor (selector, index) {
      if (index == this.currentDetailIndex) {
        return;
      }
      this.currentDetailIndex = index;
      var anchor = this.$el.querySelector(selector);
      //   document.getElementById(selector.split("#")[1]).scrollIntoView({
      //     behavior: "smooth",
      //     block: "start"
      //   });
      document.documentElement.scrollTop = anchor.offsetTop - 238;
    }
  }
};
</script>

<style lang="less" scoped>
.detail_wrap {
  padding: 94px 0 20px 0;
  background: #fff;
  min-height: calc(100vh - 114px);
  .list_right {
    padding: 0 100px;
    .fixed_top {
      position: fixed;
      top: 74px;
      width: 100%;
      background: #fff;
      .list_rigth_title {
        padding-left: 35px;
        padding-top: 23px;
        strong {
          font-size: 16px;
          color: @blue;
        }
        span {
          display: inline-block;
          height: 25px;
          line-height: 24px;
          padding: 0px 14px;
          border-radius: 4px;
          color: #fff;
          margin-left: 12px;
          &.effective {
            background-color: #aed0fa;
          }
          &.invalid {
            background-color: #d7d4d4;
          }
          &.trial {
            background-color: #fae0ae;
          }
          &.unknown {
            background-color: #ddbaf9;
          }
        }
        h6 {
          display: block;
          font-size: 18px;
          color: #2a303f;
          margin: 24px 0 34px;
          /deep/em {
            color: #ff9f2d;
          }
        }
      }
      .list_right_tab {
        border-bottom: 1px solid #dbdee5;
        li {
          display: inline-block;
          color: #9296a0;
          font-size: 16px;
          cursor: pointer;
          padding-bottom: 8px;
          margin: 0 40px;
          &:hover {
            color: @blue;
          }
          &.active {
            color: @blue;
            border-bottom: 3px solid @blue;
          }
        }
      }
    }
    .fixed_bottom {
      padding-top: 140px;
      .list_right_tab_con {
        padding: 26px 26px 26px 35px;
        li {
          font-size: 14px;
          line-height: 25px;
          color: #333;
          h6 {
            color: @blue;
            margin: 22px 0 6px;
          }
          p {
            display: inline-block;
            span {
              color: #999;
              display: inline-block;
              margin-right: 30px;
              strong {
                color: #333;
                margin-right: 5px;
              }
            }
            img {
              cursor: pointer;
              display: inline-block;
              width: 245px;
              height: 176px;
              margin: 10px;
            }
          }
          &.pd10 {
            padding-left: 10px;
            h5 {
              color: #6eabf6;
            }
          }
        }
      }
    }
  }
  .swiper-container {
    background: transparent;
    .swiper-slide {
      border-radius: 3px;
      padding: 40px;
      box-sizing: border-box;
      img {
        width: 100%;
      }
    }
  }
}
</style>
<style lang="less">
.swiper_dialog {
  background: transparent;
  .el-dialog__header {
    display: none;
  }
  .swiper-wrapper {
    min-height: 600px;
    align-items: center;
  }
}
</style>
